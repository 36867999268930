$primary: #0080ff;
$font-family-sans-serif: 'Poppins', sans-serif;

@import "../../node_modules/bootstrap/scss/bootstrap-utilities.scss";
@import "../../node_modules/bootstrap/scss/bootstrap-grid.scss";
@import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/nav";
@import "../../node_modules/bootstrap/scss/reboot";


@media (min-width: 1440px) {
    .container {
        width: 1220px !important;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .container {
        width: 958px !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .container {
        width: 738px !important;
    }
}

h1{
    font-weight: 600 !important;
}
h2{
    font-weight: 600 !important;
}
h3{
    font-weight: 600 !important;
}
h4{
    font-weight: 600 !important;
}
h5{
    font-weight: 600 !important;
}
h6{
    font-weight: 600 !important;
}