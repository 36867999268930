.foot {
    box-shadow: 0px 2px 15px rgba(25, 119, 204, 0.3);
    h1,
    h6,
    a,
    p {
        color: #2717bd;
    }

    i {
        font-size: 20px;
    }

    .social-links i {
        font-size: 18px;
    }

    .social-links a {
        display: inline-block;
        background: #1977cc;
        color: #fff;
        line-height: 18px;
        padding: 8px 0;
        margin-right: 4px;
        border-radius: 50%;
        text-align: center;
        width: 36px;
        height: 36px;
        transition: 0.3s;
    }

    .social-links a:hover {
        background: #1c84e3;
        color: #fff;
        text-decoration: none;
    }
    @media (max-width: 992px) {
        .footer-nav-container {
            justify-content: space-between !important;
        }   
    }
}
@primary-color: #0080ff;@themeColor: #0080ff;@font-family: 'Roboto', sans-serif;