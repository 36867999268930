.header {
    padding: 15px 0px;

    .logo {
        margin: 0;
        padding: 0;
        letter-spacing: 0.5px;
    }

    .logo img {
        max-height: 60px;
        height: 40px;
    }
    @media only screen and (max-width: 767px) {
        .header-logo {
            text-align: left;
            margin-left: 12px;
        }
        .logo img {
            max-height: 40px;
            height: 35px;
        }
    }

    .menu-item {
        font-weight: 500;
        position: relative;
        cursor: pointer;
        height: 100%;
        border-bottom: 2px solid #fff;
        display: flex;
        align-items: center;
    }

    .active {
        color: #2717bd;
        border-color: #2717bd;
    }

    .menu-item:hover {
        color: #2717bd;
        border-color: #2717bd;
    }

    .mobile-nav-popup {
        position: absolute;
        top: 0px;
        left: 0px;
        height: 100vh;
        width: 75vw;
        background: #2817bdc5;
        border-right: 4px solid #ffffff4f;
        box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12),
            0px 6px 16px rgba(0, 0, 0, 0.08),
            0px 9px 28px 8px rgba(0, 0, 0, 0.05);
        z-index: 10;

        .menu-item {
            height: 60px;
        }

        .menu-item {
            color: #fff;
            border-color: #fff;
        }

        .menu {
            padding: 30px 10px;
        }

    }

    .mobile-nav-popup a {
        font-weight: 500;
        padding: 5px 0px;
        color: #fff;
    }

    .mobile-nav-popup span {
        font-weight: 500;
        padding: 10px 0px;
    }

    .mobile-home-nav {
        .mobile-nav-link {
            font-size: 18px;
            color: black;
            margin: 5px 0px;
            text-align: center;
            font-weight: 600 !important;
        }
    }
}

.header2 {

    box-shadow: 0px 2px 15px rgba(25, 119, 204, 0.1);
    padding: 15px 0px;
    top: 0;
    z-index: 10;
    width: 100vw;

    .logo {
        margin: 0;
        padding: 0;
        line-height: 1;
        letter-spacing: 0.5px;
    }

    .logo img {
        max-height: 60px;
        height: 40px;
    }

    @media only screen and (max-width: 767px) {
        .header-logo {
            text-align: left;
            margin-left: 12px;
        }
    }
    .ant-input-wrapper.ant-input-group .ant-input-group-addon {
        button {
            height: 40px;
            font-size: 18px;
            margin-left: 10px;
            border-radius: 8px !important;
            background-color: #000;
            border: none;
        }
        background-color: transparent;
    }
    .ant-input-wrapper.ant-input-group {
        .ant-input-affix-wrapper.ant-input-affix-wrapper {
            height: 40px;
            overflow: hidden;
            background-color: rgba(255, 255, 255, 0.9);
            transition: all 500ms ease;
            border-radius: 8px;

            input {
                padding: 10px;
                font-size: 18px;
                background-color: transparent;
            }

            .ant-input-suffix {
                .anticon.ant-input-clear-icon {
                    font-size: 20px;
                }
            }

        }
        .ant-input-affix-wrapper.ant-input-affix-wrapper-focused.ant-input-affix-wrapper {
            background-color: #fff !important;
        }
    }

    .menu-item {
        font-weight: 500;
        position: relative;
        cursor: pointer;
        height: 100%;
        border-bottom: 2px solid #fff;
        display: flex;
        align-items: center;
    }

    .active {
        color: #2717bd;
        border-color: #2717bd;
    }

    .menu-item:hover {
        color: #2717bd;
        border-color: #2717bd;
    }

    .mobile-nav-popup {
        position: absolute;
        top: 0px;
        left: 0px;
        height: 100vh;
        width: 75vw;
        background: #2817bdc5;
        border-right: 4px solid #ffffff4f;
        box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12),
            0px 6px 16px rgba(0, 0, 0, 0.08),
            0px 9px 28px 8px rgba(0, 0, 0, 0.05);
        z-index: 10;

        .menu-item {
            height: 60px;
        }

        .menu-item {
            color: #fff;
            border-color: #fff;
        }

        .menu {
            padding: 30px 10px;
        }

    }

    .mobile-nav-popup a {
        font-weight: 500;
        padding: 5px 0px;
        color: #fff;
    }

    .mobile-nav-popup span {
        font-weight: 500;
        padding: 10px 0px;
    }
}

#topbar {
    background: #2717bd;
    transition: all 0.5s;
    z-index: 996;
    color: #fff;
    padding: 5px 0;
}

#topbar h5,
#topbar p {
    color: #fff;
    text-align: center;
    margin-bottom: 0px;
}

.cta-button {
    background-color: #000;
}

.cta-button:hover {
    background-color: #0081ff;
}
@primary-color: #0080ff;@themeColor: #0080ff;@font-family: 'Roboto', sans-serif;