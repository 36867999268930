@import '~react-responsive-carousel/lib/styles/carousel.min.css';

.pointer {
  cursor: pointer;
}

a {
  text-decoration: none !important;
}

.text-primary-dark {
  color: #202843;
}