@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes rainbow-border {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 200% 50%;
  }
}

#hoff-ai {
  overflow: hidden;
  .chat-container {
    background-color: white;
    border-radius: 18px;
    width: 350px !important;
    @media (min-width: 768px) {
      width: 400px !important;
    }

    border: 5px solid white;
    .chat-box-container {
      overflow: hidden;
      border-radius: 12px;
      .chat-box {
        height: 400px;
        @media (min-height: 768px) {
          height: 500px;
        }
        @media (min-height: 1024px) {
          height: 550px;
        }
        overflow-y: auto;
        scroll-behavior: smooth;

        .message-container.bot {
          display: flex;
          justify-content: flex-start;
          .message {
            background-color: #f1f0f0;
            border-radius: 12px;
            padding: 10px;
            margin: 5px;
            max-width: 70%;
            word-wrap: break-word;
            font-size: 12px;
          }
          .suggestions-container {
            overflow-x: auto;
            .suggestion-wrapper {
              background-size: cover;
              background-position: center;
              border-radius: 10px;
              overflow: hidden;
              padding: 0;
              height: 120px;
              width: 200px;
              justify-content: flex-end;
              position: relative;
            }
            &::-webkit-scrollbar {
              height: 5px;
            }
            &::-webkit-scrollbar-thumb {
              background-color: #0080ff;
              border-radius: 5px;
              height: 5px;
            }
            &::-webkit-scrollbar-track {
              background-color: #f1f0f0;
              border-radius: 5px;
              height: 5px;
            }

          }
          .book-now-btn {
            margin: 5px;
            background-color: #0080ff;
            color: white;
            border: none;
            border-radius: 10px;
            padding: 5px 10px;
            cursor: pointer;
            outline: none;
            transition: background-color 0.1s ease-in-out;
            &:hover {
              background-color: #005cb2;
            }
          
          }
        }
        .message-container.user {
          display: flex;
          justify-content: flex-end;
          .message {
            background-color: #0080ff;
            color: white;
            border-radius: 12px;
            padding: 10px;
            margin: 5px;
            max-width: 70%;
            word-wrap: break-word;
            font-size: 12px;
          }
        }
        &::-webkit-scrollbar {
          width: 5px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #0080ff;
          border-radius: 10px;
        }
        &::-webkit-scrollbar-track {
          background-color: #f1f0f0;
          border-radius: 10px;
        }
      }
      .input-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 10px;
        padding: 8px;

        .prompt-input {
          width: 80%;
          border: none;
          border-radius: 10px;
          padding: 8px;
          margin-right: 10px;
          outline: none;
          transition: border 0.1s ease-in-out;
        }
        .prompt-input:focus {
          border: 1.5px solid #0080ff;
        }
        .prompt-send-btn-wrapper {
          background-color: #0080ff;
          color: white;
          border: none;
          height: 40px;
          width: 40px;
          border-radius: 999px;
          cursor: pointer;
          outline: none;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .chat-header {
      background-color: #0080ff;
    }
  }
  .blink-animation {
    animation: blink 2s infinite;
  }
  .online-text {
    position: absolute;
    top: -30px;
    right: 0px;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 2px 10px;
    border-radius: 99px;
    white-space: nowrap;
    transition: opacity 0.3s ease-in-out;
  }
  .toggle-btn-container:hover .online-text {
    opacity: 1;
  }
  .multi-color-border {
    background: white;
    border: none;
    border-radius: 0;
    position: relative;
    transform: translate(0%,0%);
    border-radius: 18px;
    overflow: hidden;
    outline: none;
    &:before, &:after {
      content: '';
      position: absolute;
      bottom: 0;
      top: 0;
      left: 0;
      right: 0;
      height: auto;
      width: auto;
      z-index: -1;
      border-radius: 16px;
      background: inherit;
    }
    &:before {
        background: linear-gradient(90deg, #0080ff, #0ebeff, #47cf73, #0080ff, #ae63e4, #0080ff, white, #0080ff);
        background-size: 200% 200%;
        animation: rainbow-border 3s linear infinite
    }
    &:after {
      margin: 5px;
    }
  }
}
